import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

interface SocialProps {
  label: string
  href: string
  icon: string
}

function Social({ label, href, icon }: SocialProps): JSX.Element {
  return (
    <a href={href} aria-label={label}>
      <span className="icon">
        <i className={icon} />
      </span>
    </a>
  )
}

export default function AboutCard(): JSX.Element {
  const profileName = 'Carl B. Smiley'
  const profileHandle = '@linux_pangolin'
  const github = 'https://github.com/beeryt'
  const linkedin = 'https://www.linkedin.com/in/carl-b-smiley'
  const se = 'https://stackexchange.com/users/13595322/linux-pangolin'
  const twitter = 'https://twitter.com/linux_pangolin'

  let profileImage = (
    <img
      alt="Placeholder image"
      title=""
      src="https://bulma.io/images/placeholders/1280x960.png"
    />
  )

  const data = useStaticQuery(graphql`
    query PROFILE_IMAGE_QUERY {
      file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (data?.file?.childImageSharp?.fluid)
    profileImage = <Img fluid={data.file.childImageSharp.fluid} />

  return (
    <div className="card">
      <div className="card-image">
        <figure className="image">{profileImage}</figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">{profileName}</p>
            <p className="subtitle is-6">{profileHandle}</p>
          </div>
        </div>
        <div className="level is-mobile">
          <div className="level-item">
            <Social label="Github" href={github} icon="fab fa-github" />
          </div>
          <div className="level-item">
            <Social label="LinkedIn" href={linkedin} icon="fab fa-linkedin" />
          </div>
          <div className="level-item">
            <Social
              label="StackExchange"
              href={se}
              icon="fab fa-stack-overflow"
            />
          </div>
          <div className="level-item">
            <Social label="Twitter" href={twitter} icon="fab fa-twitter" />
          </div>
        </div>
      </div>
    </div>
  )
}
